import { SvelteComponent } from "svelte"

import ModalAlert from "./ModalAlert.svelte"
import ModalConfirm from "./ModalConfirm.svelte"

export interface DialogProps {
  show?: boolean
  lightbox?: boolean
  height?: string
  width?: string
  [other: string]: any
}

export function asyncDialog<T>(
  DialogComponent: typeof SvelteComponent<any>,
  props: DialogProps = {},
  target = document.body
) {
  const dialog = new DialogComponent({ props, target })

  return new Promise<T>((resolve, _reject) => {
    dialog.$on("close", ({ detail }) => {
      dialog.$set({ show: false })
      resolve(detail)
      dialog.$destroy()
    })

    dialog.$set({ show: true })
  })
}

export function asyncAlert(
  title: string,
  message: string,
  props: DialogProps = {}
) {
  // Assume lightbox by default.
  props.lightbox = props.lightbox ?? true
  return asyncDialog<void>(ModalAlert, { ...props, message, title })
}

export function asyncConfirm(
  message: string,
  props: DialogProps = {},
  Modal: typeof SvelteComponent<any> = ModalConfirm
) {
  // Assume lightbox by default.
  props.lightbox = props.lightbox ?? true
  return asyncDialog<boolean>(Modal, { ...props, message })
}
